import { Box, Typography } from '@mui/material';

const PawDifference = () => {
  const cardGradientHandler = index => {
    if (index === 0) {
      return 'linear-gradient(180deg, rgba(234, 221, 255, 0.8) 0%, rgba(255, 255, 255, 0) 163.71%)';
    } else if (index === 1) {
      return 'linear-gradient(180deg, rgba(221, 229, 255, 0.8) 0%, rgba(255, 255, 255, 0) 163.71%)';
    } else {
      return 'linear-gradient(180deg, rgba(255, 227, 221, 0.8) 0%, rgba(255, 255, 255, 0) 163.71%)';
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: { xs: 'auto', lg: 'auto' },
        padding: { xs: '64px 16px', lg: '128px 32px' },
        backgroundColor: 'rgba(245, 246, 250, 1)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontFamily: 'Inter, serif',
          fontWeight: 'bold',
          mb: 3,
          fontSize: { xs: '28px', lg: '32px' },
          lineHeight: '40px',
          color: '#1E223A',
          textAlign: 'center',
        }}
      >
        The PawPilot AI Difference
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: '24px',
          mt: 4,
          mx: 'auto',
          px: 2,
        }}
      >
        {[
          {
            subtitle: 'Precision',
            title: 'Paw AI Search',
            firstText: `A search engine built for veterinary medicine, providing`,
            boldText: 'quick, reliable answers from trusted sources',
            finalText: ', so veterinary professionals can make confident decisions.',
          },
          {
            subtitle: 'Efficient Patient Management',
            title: 'Streamlined Pet Care Workflow',
            boldText: 'Optimize your clinic’s operations',
            firstText:
              'with AI tools that help access patient insights, clinical guidance, and medical knowledge faster,',
            finalText:
              'freeing up more time for veterinary professionals to focus on care.',
          },
          {
            subtitle: 'Expanding AI Capabilities',
            title: 'The Future of Veterinary Intelligence',
            boldText: 'We’re building next-gen tools for clinical support',
            firstText: ', integrating AI into more parts of your workflow,',
            finalText:
              'designed to unlock even more value for veterinary teams.',
          },
        ].map(({ subtitle, title, firstText, boldText, finalText }, index) => (
          <Box
            key={index}
            sx={{
              width: { xs: '100%', sm: '300px', lg: '368px' },
              borderRadius: '12px',
              backgroundColor: '#F3F5FF',
              border: '1px solid rgba(0, 17, 102, 0.1)',
              boxShadow: '0px 2px 4px -2px rgba(0, 0, 0, 0.08)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              '&:hover': {
                boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.1)',
                transition: 'all 0.3s ease-in-out',
                transform: 'translateY(-4px)',
              },
            }}
          >
            <Box
              sx={{
                background: cardGradientHandler(index),
                height: '100px',
                padding: '16px',
                borderBottom: '1px solid rgba(0, 17, 102, 0.1)',
                borderRadius: '12px 12px 0 0',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  fontFamily: 'Inter, serif',
                  color: 'rgba(0, 9, 51, 0.65)',
                  fontWeight: '400',
                  fontSize: '16px',
                  mb: 1,
                }}
              >
                {subtitle}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'Inter, serif',
                  fontWeight: 'bold',
                  fontSize: '20px',
                  lineHeight: '28px',
                  color: 'rgba(0, 6, 38, 0.9)',
                }}
              >
                {title}
              </Typography>
            </Box>

            <Box
              sx={{
                padding: '16px',
                textAlign: 'left',
                flexGrow: 1,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontFamily: 'Inter, serif',
                  color: '#555',
                  fontSize: '16px',
                  lineHeight: '24px',
                }}
              >
                {index === 0 ? (
                  <>
                    {firstText} <b>{boldText}</b> {finalText}
                  </>
                ) : (
                  <>
                    <b>{boldText}</b> {firstText} <b>{finalText}</b>
                  </>
                )}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default PawDifference;
