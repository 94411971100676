import React from 'react';
import { Box, Typography, Link } from '@mui/material';

export default function Footer() {
  return (
    <Box
      sx={{
        width: '100%',
        borderTop: '1px solid #e0e0e0',
        backgroundColor: 'rgba(0, 21, 128, 0.1)', // #001580 with 10% opacity
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: 2,
      }}
    >
      <Typography variant="body2" sx={{ color: '#666' }}>
        © 2025 PawPilot AI all rights reserved
      </Typography>
      <Typography variant="body2" sx={{ color: '#666' }}>
        <Link href="#" color="inherit" sx={{ mr: 2 }}>
          Privacy Policy
        </Link>
        <Link href="#" color="inherit">
          Terms of use
        </Link>
      </Typography>
    </Box>
  );
}
