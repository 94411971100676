import React from 'react';
import { AppBar, Toolbar, Box, Button, Typography, Avatar } from '@mui/material';
import pawLogo from '../../assets/Pawlogo.png';

export default function NavBar({ user, onLogoutClick }) {
  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: '#fff',
        borderBottom: '1px solid #e0e0e0',
        boxShadow: 'none',
        padding: '0 16px',
      }}
    >
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* Logo + Title Wrapped */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '211.24px',
            height: '46.69px',
            gap: '1px',
          }}
        >
          <Box
            component="img"
            src={pawLogo}
            alt="Paw Logo"
            sx={{
              width: '37.08px',
              height: '33.58px',
              transform: 'rotate(-26.78deg)',
              mr: 1,
            }}
          />
          <Typography
            sx={{
              fontFamily: 'Merriweather',
              fontWeight: 400,
              fontSize: '30px',
              lineHeight: '85%',
              letterSpacing: '-4%',
              color: '#000626',
            }}
          >
            Paw Search
          </Typography>
        </Box>

        {/* Show the Logout button when the user is logged in */}
        {user && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Avatar src={user.user_metadata?.avatar_url} sx={{ width: 32, height: 32 }} />
            <Button
              variant="outlined"
              onClick={onLogoutClick}
              sx={{
                minWidth: '81px',
                height: '34px',
                padding: '6px 16px',
                borderRadius: '8px',
                border: '1px solid rgba(76, 100, 217, 1)',
                color: 'rgba(76, 100, 217, 1)',
                textTransform: 'none',
                fontSize: '12px',
                fontWeight: 500,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              Logout
            </Button>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}
