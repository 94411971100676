import React, { useState } from 'react';
import { Box, Button, Typography, Paper, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import supabase from './supabaseClient';
import pawLogo from '../assets/Pawlogo.png';
import googleIcon from '../assets/google.png';

const LoginPage = ({ onLogin }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleGoogleLogin = async () => {
    setLoading(true);
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      redirectTo: window.location.origin + '/#/search',
    });
    if (error) {
      console.error('Login failed:', error);
      setError(error.message);
    } else {
      onLogin();
    }
    setLoading(false);
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#F3F5FF',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          width: '420px',
          padding: '40px',
          borderRadius: '16px',
          textAlign: 'center',
          background: '#FFFFFF',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', // Ensures center alignment
          justifyContent: 'center',
        }}
      >
        {/* Logo with extra spacing */}
        <Box
          component="img"
          src={pawLogo}
          alt="Paw Logo"
          sx={{
            width: '160px',
            height: '140px',
            marginBottom: '32px', // Increased spacing
          }}
        />

        

        {error && <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>}

        {/* Centered Google Login Button */}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center', // Centers the button horizontally
          }}
        >
          <Button
            variant="outlined"
            onClick={handleGoogleLogin}
            disabled={loading}
            sx={{
              width: '300px',
              height: '56px',
              borderRadius: '12px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px',
              textTransform: 'none',
              color: '#000626E5',
              borderColor: '#000626E5',
              fontFamily: 'inter,var(--FontfamilyHeading)',
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: 'var(--LineheightHeading4)',
              '&:hover': {
                backgroundColor: '#f0f2ff',
              },
            }}
          >
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              <>
                <Box component="img" src={googleIcon} alt="Google Icon" sx={{ width: 24, height: 24 }} />
                Continue with Google
              </>
            )}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default LoginPage;