import React from 'react';
import { Box, Typography, Button, Paper, Link, Chip, Skeleton } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import pawLogo from '../../assets/Pawlogo.png';

function parseBoldText(text) {
  const regex = /\*\*(.*?)\*\*/g;
  let match;
  let lastIndex = 0;
  const result = [];

  while ((match = regex.exec(text)) !== null) {
    if (match.index > lastIndex) {
      result.push(text.slice(lastIndex, match.index));
    }
    result.push(<strong key={match.index}>{match[1]}</strong>);
    lastIndex = regex.lastIndex;
  }

  if (lastIndex < text.length) {
    result.push(text.slice(lastIndex));
  }

  return result;
}

export default function SearchResults({ query, output, isLoading, handleGoBack }) {
  let answerText = output;
  let referencesArray = [];

  // Define a unique marker to separate the main text from the final payload.
  const marker = "|||URLS|||";

  // If the output contains the marker, split it into answer text and JSON payload.
  if (output && output.includes(marker)) {
    const parts = output.split(marker);
    answerText = parts[0].trim();
    try {
      const payload = JSON.parse(parts[1].trim());
      referencesArray = payload.extracted_urls || [];
    } catch (err) {
      console.error("Error parsing final payload JSON:", err);
    }
  }

  // Optional: a helper to display only the domain of a URL.
  const getDomainFromUrl = (url) => {
    try {
      const { hostname } = new URL(url);
      return hostname.replace('www.', '');
    } catch (err) {
      return url;
    }
  };

  return (
    <Box
      sx={{
        margin: '20px auto',
        width: { xs: '95%', sm: '90%', md: '85%' },
        backgroundColor: '#fff',
        boxSizing: 'border-box',
        p: { xs: 3, sm: 4 },
        borderRadius: 2,
        minHeight: '600px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        overflowX: 'hidden',
      }}
    >
      {/* Header with Centered Paw Logo & Title */}
      <Box 
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center', 
          width: '100%', 
          mb: { xs: 4, sm: 6 },
          position: 'relative' 
        }}
      >
        {/* Go Back Button */}
        <Button 
          onClick={handleGoBack} 
          variant="text" 
          sx={{ 
            position: 'absolute', 
            left: 0, 
            minWidth: 0, 
            padding: 0 
          }}
        >
          <ArrowForwardIcon 
            sx={{ transform: 'rotate(180deg)', fontSize: '28px', color: '#4C64D9' }}
          />
        </Button>
        {/* Centered Logo & Title */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            component="img"
            src={pawLogo}
            alt="Paw Logo"
            sx={{
              width: { xs: '50px', sm: '60px' },
              height: { xs: '45px', sm: '55px' },
              transform: 'rotate(-26.78deg)',
              mr: 1,
            }}
          />
          <Typography
            sx={{
              fontFamily: 'Merriweather',
              fontWeight: 400,
              fontSize: { xs: '38px', sm: '44px' },
              lineHeight: '85%',
              letterSpacing: '-4%',
              color: '#000626',
              textAlign: 'center',
            }}
          >
            Paw Search
          </Typography>
        </Box>
      </Box>

      {/* Chat Container */}
      <Box
        sx={{
          width: '100%',
          maxWidth: '800px',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#FFFFFF',
          p: 3,
          overflowY: 'auto',
          height: 'auto',
          minHeight: { xs: '400px', sm: '500px' },
          maxHeight: { xs: '500px', md: '650px' },
          boxShadow: 'none',
        }}
      >
        {/* User Query Bubble */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <Paper
            sx={{
              p: 2,
              borderRadius: '12px',
              backgroundColor: '#DAC5E980',
              maxWidth: '80%',
              alignSelf: 'flex-end',
              boxShadow: 'none',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Geist, sans-serif',
                fontSize: '16px',
                color: '#000626',
                textAlign: 'right',
              }}
            >
              {query || 'Your question will appear here'}
            </Typography>
          </Paper>
        </Box>

        {/* Sources Section */}
        <Box sx={{ mt: 2 }}>
          <Typography sx={{ fontWeight: 600, color: '#4C64D9', mb: 1 }}>
            📎 Sources
          </Typography>
          {/* 
            Show a loading clip (using Skeletons) if no sources yet (or if still loading).
            Otherwise, show the first five sources in animated chips.
          */}
          {(isLoading || referencesArray.length === 0) ? (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {[...Array(5)].map((_, idx) => (
                <Skeleton key={idx} variant="rectangular" width={80} height={32} />
              ))}
            </Box>
          ) : (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {referencesArray.slice(0, 5).map((ref, index) => (
                <Chip
                  key={index}
                  label={getDomainFromUrl(ref)}
                  component="a"
                  href={ref}
                  target="_blank"
                  clickable
                  sx={{
                    textDecoration: 'none',
                    color: '#4C64D9',
                    backgroundColor: '#F3F5FF',
                    fontWeight: 500,
                    animation: 'fadeIn 0.5s ease-in-out',
                    '@keyframes fadeIn': {
                      from: { opacity: 0, transform: 'translateX(-20px)' },
                      to: { opacity: 1, transform: 'translateX(0)' },
                    },
                  }}
                />
              ))}
            </Box>
          )}
        </Box>

        {/* Streaming System Response */}
        <Box sx={{ mt: 3 }}>
          <Typography sx={{ fontWeight: 600, fontSize: '18px', color: '#262B46', mb: 1 }}>
            🐾 Results
          </Typography>
          <Typography sx={{ color: '#666', mb: 2 }}>
            {isLoading ? 'Fetching results...' : ''}
          </Typography>
          <Typography
            sx={{
              whiteSpace: 'pre-wrap',
              color: '#333',
              fontSize: '16px',
              fontFamily: 'Geist, sans-serif',
              lineHeight: '1.5',
            }}
          >
            {parseBoldText(answerText) || (
              <Typography
                sx={{
                  fontStyle: 'italic',
                  color: '#999',
                  animation: 'blinker 1s linear infinite',
                  '@keyframes blinker': { '50%': { opacity: 0 } },
                }}
              >
                Processing...
              </Typography>
            )}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
