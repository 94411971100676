import { Box, Typography, Grid } from '@mui/material';
import PetsIcon from '@mui/icons-material/Pets'; // Dog icon for Bryan
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver'; // Speaking icon for Nick
import SmartToyIcon from '@mui/icons-material/SmartToy'; // Robot icon for Bidhan
import BrushIcon from '@mui/icons-material/Brush'; // Art icon for Andrew
import MedicalServicesIcon from '@mui/icons-material/MedicalServices'; // Medical icon for Melissa

const About = () => {
  const teamMembers = [
    {
      name: 'Bryan',
      role: 'Founder',
      icon: <PetsIcon sx={{ fontSize: '80px', color: '#4C64D9' }} />,
    },
    {
      name: 'Nick',
      role: 'Pawsident of Sales',
      icon: <RecordVoiceOverIcon sx={{ fontSize: '80px', color: '#4C64D9' }} />,
    },
    {
      name: 'Bidhan',
      role: 'Founding AI/ML Engineer',
      icon: <SmartToyIcon sx={{ fontSize: '80px', color: '#4C64D9' }} />,
    },
    {
      name: 'Andrew',
      role: 'Head of Product Design',
      icon: <BrushIcon sx={{ fontSize: '80px', color: '#4C64D9' }} />,
    },
    {
      name: 'Melissa',
      role: 'Advisor, DVM',
      icon: <MedicalServicesIcon sx={{ fontSize: '80px', color: '#4C64D9' }} />,
    },
  ];

  return (
    <Box
      component="section"
      sx={{
        backgroundColor: '#FFFFFF',
        minWidth: '100vw',
        height: 'auto',
        pb: '128px',
      }}
    >
      {/* About Us Section */}
      <Box
        component="section"
        sx={{
          backgroundColor: '#F9FAFB', // Light background for a clean look
          minWidth: '100vw',
          height: 'auto',
          py: '64px',
          px: { xs: '16px', lg: '32px' },
        }}
      >
        <Box
          sx={{
            textAlign: 'center',
            maxWidth: '800px',
            mx: 'auto',
            mb: 6,
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontFamily: 'Inter, serif',
              fontWeight: 500,
              fontSize: '20px',
              color: '#4C64D9', // Primary color for the subtitle
              pb: '12px',
            }}
          >
            🐾 About PawPilot AI
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: 'Merriweather, serif',
              fontSize: { xs: '28px', lg: '36px' },
              fontWeight: '700',
              color: 'rgba(0, 6, 38, 0.9)',
              mb: 4,
            }}
          >
            Our Story
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontFamily: 'Inter, serif',
              fontWeight: 400,
              fontSize: { xs: '16px', lg: '18px' },
              lineHeight: '28px',
              color: 'rgba(0, 6, 38, 0.75)',
              textAlign: 'justify', // Justify text for better readability
              mb: 4,
            }}
          >
            At PawPilot AI, we’re driven by a passion for veterinary care and innovation. Our mission is
            to empower veterinarians with instant, AI-powered insights that enhance decision-making and
            improve animal health. By simplifying workflows and providing reliable clinical information,
            we aim to make a meaningful difference in the lives of veterinary professionals and the
            animals they care for.
          </Typography>
        </Box>

        {/* Visual Divider */}
        <Box
          sx={{
            width: '100%',
            height: '2px',
            backgroundColor: '#E0E0E0',
            mb: 8,
          }}
        />

        {/* Team Section */}
        <Box
          sx={{
            textAlign: 'center',
            py: '64px',
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontFamily: 'Merriweather, serif',
              fontSize: { xs: '28px', lg: '32px' },
              fontWeight: '700',
              color: 'rgba(0, 6, 38, 0.9)',
              mb: '32px',
            }}
          >
            Meet Our Team
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {teamMembers.map((member, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    gap: 2,
                    padding: '24px',
                    border: '1px solid #E0E0E0',
                    borderRadius: '12px',
                    backgroundColor: '#FFFFFF',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                    '&:hover': {
                      transform: 'translateY(-10px)', // Lift the card on hover
                      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)', // Add a stronger shadow
                      backgroundColor: '#F3F5FF', // Add a subtle background color on hover
                    },
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: 'Merriweather, serif',
                      fontSize: '22px',
                      fontWeight: '700', // Bold font for names
                      color: '#4C64D9', // Add a primary color for names
                    }}
                  >
                    {member.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: 'Inter, serif',
                      fontSize: '16px',
                      color: 'rgba(0, 6, 38, 0.75)',
                      lineHeight: '24px',
                    }}
                  >
                    {member.role}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default About;
