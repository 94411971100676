import React from 'react';
import { Box, Typography, TextField, Button, Chip } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import chewy from '../../assets/chewy.png';
import hills from '../../assets/hills.png';
import medvet from '../../assets/medvet.png';
import merck from '../../assets/merck.png';
import instinct from '../../assets/instinct.png';
import pawLogo from '../../assets/Pawlogo.png';
import whitepawlogo from '../../assets/whitepawlogo.png';

export default function Hero({ query, setQuery, isLoading, handleStartStream }) {
  const onSubmit = (e) => {
    e.preventDefault();
    handleStartStream();
  };

  return (
    <Box
      sx={{
        margin: '50px auto',
        width: { xs: '90%', sm: '80%', md: '85%' },
        backgroundColor: '#fff',
        boxSizing: 'border-box',
        p: 4,
        borderRadius: 2,
        minHeight: '690px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {/* Header Section with Centered Paw Logo + "Paw Search" */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mb: 6,
        }}
      >
        <Box
          component="img"
          src={pawLogo}
          alt="Paw Logo"
          sx={{
            width: '77.26px',
            height: '69.97px',
            transform: 'rotate(-26.78deg)',
            mr: 2,
          }}
        />
        <Typography
          sx={{
            fontFamily: 'Merriweather',
            fontWeight: 400,
            fontSize: '64px',
            lineHeight: '85%',
            letterSpacing: '-4%',
            color: '#000626',
          }}
        >
          Paw Search
        </Typography>
      </Box>

      {/* Search Form */}
      <Box
        component="form"
        onSubmit={onSubmit}
        sx={{
          width: '674px',
          height: '130px',
          display: 'flex',
          alignItems: 'flex-start',
          borderRadius: '8px',
          border: '1px solid #00116640',
          padding: '16px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          position: 'relative',
          mb: 4,
          backgroundColor: '#FFFFFF',
        }}
      >
        <TextField
          fullWidth
          variant="standard"
          placeholder="Ask a medical question to get started"
          multiline
          rows={2}
          InputProps={{
            disableUnderline: true,
            sx: {
              textAlign: 'left',
              padding: '0px',
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
              color: '#000',
            },
          }}
          sx={{
            ml: 1,
            fontSize: '18px',
            fontFamily: 'Geist, sans-serif',
            fontWeight: 400,
            letterSpacing: '-2%',
            textAlign: 'left',
            paddingTop: '0px',
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
            overflowWrap: 'break-word',
          }}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          disabled={isLoading}
        />

        {/* Search Button */}
        <Button
          type="submit"
          sx={{
            width: '107px',
            height: '28.97px',
            position: 'absolute',
            bottom: '12px',
            right: '12px',
            padding: '6px 12px',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '6px',
            backgroundColor: '#4C64D9',
            color: '#fff',
            fontSize: '12px',
            fontWeight: '400',
            fontFamily: 'Geist, sans-serif',
            textTransform: 'none',
            letterSpacing: '-2%',
            '&:hover': {
              backgroundColor: '#3A4CB7',
            },
          }}
          disabled={isLoading}
        >
          <Box
            component="img"
            src={whitepawlogo}
            alt="Paw Logo"
            sx={{ width: '16px', height: '16px' }}
          />
          Search
          <ArrowForwardIcon sx={{ fontSize: '16px' }} />
        </Button>
      </Box>

      {/* Quick Action Chips */}
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          flexWrap: 'wrap',
          justifyContent: 'center',
          mb: 4,
        }}
      >
        <Chip
          label="Ask about labs"
          variant="outlined"
          color="primary"
          onClick={() => setQuery('Ask about labs')}
          sx={{
            fontFamily: 'Geist, sans-serif',
            fontSize: '12px',
            fontWeight: 400,
            letterSpacing: '-2%',
            backgroundColor: '#FFFFFF',
            color: '#4C64D9',
            border: '1px solid #00116640',
            borderRadius: '8px',
          }}
        />
        <Chip
          label="Write a patient handout"
          variant="outlined"
          color="primary"
          onClick={() => setQuery('Write a patient handout')}
          sx={{
            fontFamily: 'Geist, sans-serif',
            fontSize: '12px',
            fontWeight: 400,
            letterSpacing: '-2%',
            backgroundColor: '#FFFFFF',
            color: '#4C64D9',
            border: '1px solid #00116640',
            borderRadius: '8px',
          }}
        />
        <Chip
          label="Ask about drug side effects"
          variant="outlined"
          color="primary"
          onClick={() => setQuery('Ask about drug side effects')}
          sx={{
            fontFamily: 'Geist, sans-serif',
            fontSize: '12px',
            fontWeight: 400,
            letterSpacing: '-2%',
            backgroundColor: '#FFFFFF',
            color: '#4C64D9',
            border: '1px solid #00116640',
            borderRadius: '8px',
          }}
        />
      </Box>

      {/* Trusted By Section - Logos */}
      <Box sx={{ mt: '150px', textAlign: 'center' }}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'Merriweather',
            fontWeight: 300,
            fontSize: '24px',
            lineHeight: '100%',
            letterSpacing: '-3%',
            textAlign: 'center',
            color: '#262B46',
            mb: 2,
          }}
        >
          Trusted by
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '70px' }}>
          <Box component="img" src={chewy} alt="Chewy" sx={{ width: '122px', height: '62px' }} />
          <Box component="img" src={hills} alt="Hills" sx={{ width: '122px', height: '62px' }} />
          <Box component="img" src={medvet} alt="MedVet" sx={{ width: '122px', height: '62px' }} />
          <Box component="img" src={merck} alt="Merck" sx={{ width: '122px', height: '62px' }} />
          <Box component="img" src={instinct} alt="Instinct" sx={{ width: '122px', height: '62px' }} />
        </Box>
      </Box>
    </Box>
  );
}
