import { useEffect, useState } from 'react';
import { Navigate, Route, HashRouter as Router, Routes } from 'react-router-dom';
import AI_Assistant from './AI_Assistant';
import { Box, CircularProgress, Typography } from '@mui/material';
import axios from 'axios';
import AccountInfoPage from './components/AccountInfo';
import LandingPage from './components/LandingPage'; 
import LoginPage from './components/LoginPage';
import PaymentPage from './components/PaymentPage';
import PaymentSuccess from './components/PaymentSuccess';
import SignUpPage from './components/SignupPage';
import SearchStream from './components/Search/SearchStream';
import supabase from './components/supabaseClient';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [isFetchingSubscription, setIsFetchingSubscription] = useState(false);

  // Check session via Supabase
  const fetchSession = async () => {
    const { data: { session } } = await supabase.auth.getSession();
    if (session) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
    setIsAuthChecked(true);
  };

  // Optionally fetch subscription status using the session token
  const fetchSubscriptionStatus = async () => {
    const { data: { session } } = await supabase.auth.getSession();
    if (session) {
      try {
        const token = session.access_token;
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/pawai-backend2/auth/user/organization`,
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        );
        setHasSubscription(response.data.has_subscription);
      } catch (error) {
        console.error('Error fetching subscription data:', error);
        if (error.response && error.response.status === 401) {
          handleLogout();
        }
      } finally {
        setIsFetchingSubscription(false);
      }
    }
  };

  useEffect(() => {
    fetchSession();
  }, []);

  const handleLogin = () => {
    // Refresh the session after a successful login
    fetchSession();
  };

  const handleLogout = () => {
    supabase.auth.signOut();
    setIsLoggedIn(false);
    setHasSubscription(false);
  };

  if (!isAuthChecked || isFetchingSubscription) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh'
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Router>
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#F3F5FF',
          overflow: 'hidden'
        }}
      >
        <Box
          sx={{
            flex: 1,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            overflow: 'hidden',
            marginTop: isLoggedIn ? '5px' : '0'
          }}
        >
          <Routes>
            {/* Automatically redirect logged in users to /search */}
            <Route path="/" element={isLoggedIn ? <Navigate to="/search" replace /> : <LandingPage />} />

            <Route
              path="/login"
              element={
                isLoggedIn ? (
                  <Navigate to="/search" replace />
                ) : (
                  <LoginPage onLogin={handleLogin} />
                )
              }
            />

            <Route path="/signup" element={<SignUpPage onSignUp={handleLogin} />} />

            <Route
              path="/ai-assistant"
              element={
                isLoggedIn ? (
                  <Box
                    sx={{
                      width: '100%',
                      height: 'calc(100vh - 77px)',
                      position: 'relative',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'stretch'
                    }}
                  >
                    <Box
                      sx={{
                        filter: hasSubscription ? 'none' : 'blur(4px)',
                        pointerEvents: hasSubscription ? 'auto' : 'none',
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden'
                      }}
                    >
                      <AI_Assistant />
                    </Box>
                    {!hasSubscription && (
                      <Box
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          zIndex: 1
                        }}
                      >
                        <PaymentPage />
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />

            <Route
              path="/payment-success"
              element={<PaymentSuccess onCheckSubscription={() => fetchSubscriptionStatus()} />}
            />

            <Route path="/payment" element={<PaymentPage />} />

            <Route path="/account-info" element={isLoggedIn ? <AccountInfoPage /> : <Navigate to="/login" replace />} />

            <Route path="/help" element={<Typography variant="h4">Help Page</Typography>} />

            <Route
              path="/search"
              element={isLoggedIn ? <SearchStream /> : <Navigate to="/login" replace />}
            />
          </Routes>
        </Box>
      </Box>
    </Router>
  );
};

export default App;
