import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Dialog,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
  Autocomplete,
  CircularProgress
} from '@mui/material';
import supabase from './../supabaseClient';

import NavBar from './NavBar';
import Hero from './Hero';
import Footer from './Footer';
import SearchResults from './SearchResults';
import pawLogo from '../../assets/Pawlogo.png';

// ========================================================
//                  CollegeAutocomplete
// ========================================================
const CollegeAutocomplete = ({ onCollegeSelect }) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [manualEntry, setManualEntry] = useState(false);

  // Function to fetch college data from the College Scorecard API
  const fetchCollegeData = async (searchTerm) => {
    if (!searchTerm || searchTerm.length < 1) return [];
    
    const API_KEY = process.env.REACT_APP_COLLEGE_SCORECARD_API_KEY;
    const baseURL = 'https://api.data.gov/ed/collegescorecard/v1/schools';
    // Customize the fields to retrieve
    const fields = 'id,school.name,school.city,school.state';
    // Build search parameters - search by name
    const searchParam = `school.name=${encodeURIComponent(searchTerm)}`;
    // Build URL with pagination parameters
    const url = `${baseURL}?api_key=${API_KEY}&${searchParam}&fields=${fields}&per_page=200`;
    
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error fetching data: ${response.status}`);
      }
      const data = await response.json();
      
      const mappedData = data.results.map(item => ({
        id: item.id,
        name: item['school.name'],
        city: item['school.city'],
        state: item['school.state']
      }));
      
      return mappedData;
    } catch (error) {
      console.error('Failed to fetch college data:', error);
      return [];
    }
  };

  // Debounce utility
  const debounce = (func, delay) => {
    let debounceTimer;
    return function(...args) {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce(async (searchTerm) => {
      if (searchTerm.length >= 1) {
        setLoading(true);
        const results = await fetchCollegeData(searchTerm);
        setOptions(results);
        setLoading(false);
      } else {
        setOptions([]);
      }
    }, 500),
    []
  );

  // Update search results when input changes
  useEffect(() => {
    debouncedSearch(inputValue);
  }, [inputValue, debouncedSearch]);

  // Handle manual entry selection
  const handleManualEntry = () => {
    setManualEntry(true);
    onCollegeSelect({ name: inputValue, city: '', state: '' });
  };

  if (manualEntry) {
    return (
      <TextField
        label="School/Institution Name"
        variant="outlined"
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
          onCollegeSelect({ name: e.target.value, city: '', state: '' });
        }}
        fullWidth
        size="small"
        InputProps={{
          sx: {
            borderRadius: '8px',
            height: '40px',
            '& input::placeholder': { fontStyle: 'italic', fontSize: '0.8em' },
          }
        }}
      />
    );
  }

  return (
    <Autocomplete
      id="college-search"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={options}
      getOptionLabel={(option) =>
        `${option.name}${option.city ? `, ${option.city}` : ''}${
          option.state ? `, ${option.state}` : ''
        }`
      }
      loading={loading}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event, value) => {
        onCollegeSelect(value);
      }}
      noOptionsText={
        <Box>
          <Typography variant="body2">No colleges found</Typography>
          <Button 
            size="small" 
            onClick={handleManualEntry}
            sx={{ textTransform: 'none', mt: 1 }}
          >
            Other
          </Button>
        </Box>
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search for your college or institution..."
          variant="outlined"
          size="small"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
            sx: {
              borderRadius: '8px',
              height: '40px',
              '& input::placeholder': { fontStyle: 'italic', fontSize: '0.75em' },
            }
          }}
        />
      )}
    />
  );
};

// ========================================================
//                  Main Component
// ========================================================
function SearchStream() {
  const [query, setQuery] = useState('');
  const [output, setOutput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [showResultsPage, setShowResultsPage] = useState(false);

  const [user, setUser] = useState(null);
  const [showProfileDialog, setShowProfileDialog] = useState(false);
  
  // Profile form state (mandatory fields)
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [profession, setProfession] = useState('');

  // Optional fields
  const [companyName, setCompanyName] = useState('');
  const [isStudent, setIsStudent] = useState(false);
  const [schoolName, setSchoolName] = useState('');

  const abortControllerRef = useRef(null);
  const navigate = useNavigate();

  // -----------------------------------------------------
  //   1) On mount, fetch user session & user profile
  // -----------------------------------------------------
  useEffect(() => {
    const fetchUserAndProfile = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      const currentUser = session ? session.user : null;
      setUser(currentUser);
  
      if (currentUser) {
        // Fetch the user's record from "google_users" 
        const { data, error } = await supabase
          .from('google_users')
          .select('*')
          .eq('id', currentUser.id)
          .single();

        // If we found the row with no supabase error, populate the fields
        if (data) {
          setFirstName(data.first_name || '');
          setLastName(data.last_name || '');
          setProfession(data.profession || '');
          setCompanyName(data.company_name || '');
          setIsStudent(data.is_student || false);
          setSchoolName(data.school_name || '');
        
          // Check if required fields are filled
          if (!data.first_name || !data.last_name || !data.profession) {
            setShowProfileDialog(true);
          } else {
            setShowProfileDialog(false);
          }
        }
        
      }
    };
  
    // Run once
    fetchUserAndProfile();
  
    // Listen for auth state changes so we can re-fetch if user logs out/in
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      const currentUser = session ? session.user : null;
      setUser(currentUser);

      if (currentUser) {
        // Re-fetch or re-check
        (async () => {
          const { data, error } = await supabase
            .from('google_users')
            .select('*')
            .eq('id', currentUser.id)
            .single();

          if (!error && data) {
            setFirstName(data.first_name || '');
            setLastName(data.last_name || '');
            setProfession(data.profession || '');
            setCompanyName(data.company_name || '');
            setIsStudent(data.is_student || false);
            setSchoolName(data.school_name || '');

            // Show the dialog only if required fields are missing
            if (!data.first_name || !data.last_name || !data.profession) {
              setShowProfileDialog(true);
            } else {
              setShowProfileDialog(false);
            }
          } else {
            setShowProfileDialog(true);
          }
        })();
      } else {
        setShowProfileDialog(false);
      }
    });
  
    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [navigate]);

  // -----------------------------------------------------
  //   2) Logout
  // -----------------------------------------------------
  const handleLogout = async () => {
    try {
      console.log("Logging out...");
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      
      console.log("Logout successful, clearing user state");
      setUser(null);
      localStorage.removeItem('access_token');
      
      console.log("Redirecting to home page");
      navigate('/', { replace: true });
      
      // Fallback redirection
      setTimeout(() => {
        window.location.href = '/';
      }, 500);
    } catch (err) {
      console.error("Error during logout:", err);
    }
  };

  // -----------------------------------------------------
  //   3) Profile Update
  // -----------------------------------------------------
  const updateProfile = async () => {
    if (!firstName.trim() || !lastName.trim() || !profession.trim()) {
      alert("Please fill in First Name, Last Name, and Profession.");
      return;
    }
    
    try {
      const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000';
      const { data: { session } } = await supabase.auth.getSession();
      const token = session?.access_token;
      const headers = { 'Content-Type': 'application/json' };
      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      }
      const payload = {
        first_name: firstName,
        last_name: lastName,
        profession,
        company_name: companyName,
        is_student: isStudent,
        school_name: isStudent ? schoolName : null,
      };
      console.log("Profile update payload:", payload);

      // Hit your backend route to update
      const response = await fetch(`${API_URL}/pawai-backend2/search/update-profile`, {
        method: 'POST',
        headers,
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        const errorData = await response.json();
        console.error("Profile update error:", errorData);
        throw new Error(`Profile update failed with status ${response.status}`);
      }

      // If everything went fine, close the dialog
      setShowProfileDialog(false);
    } catch (err) {
      console.error("Error updating profile:", err);
    }
  };

  // -----------------------------------------------------
  //   4) The streaming request logic
  // -----------------------------------------------------
  const handleStartStream = async (event) => {
    if (event && event.preventDefault) event.preventDefault();
    if (!query.trim()) return;

    setShowResultsPage(true);
    setOutput('');
    setError('');
    setIsLoading(true);

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    try {
      const { data: { session } } = await supabase.auth.getSession();
      const token = session?.access_token;
      const headers = { 'Content-Type': 'application/json' };
      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      }
      console.log("Token used:", token);

      const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000';
      const response = await fetch(`${API_URL}/pawai-backend2/search/streamingresponse`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ query }),
        signal: abortController.signal,
      });

      if (!response.ok) {
        throw new Error(`Request failed with status ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value, { stream: true });
        setOutput((prev) => prev + chunk);
      }
    } catch (err) {
      if (err.name === 'AbortError') {
        setOutput((prev) => prev + '\n\n[Stream aborted by user]');
      } else {
        console.error('Error:', err);
        setError(`Error: ${err.message}`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoBack = () => {
    setShowResultsPage(false);
    setOutput('');
    setQuery('');
    setError('');
  };

  // -----------------------------------------------------
  //   5) Render
  // -----------------------------------------------------

  // If the user is not signed in, show a welcome / sign-in page
  if (!user) {
    return (
      <Box
        sx={{
          minHeight: '100vh',
          backgroundColor: '#F3F5FF',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 2,
        }}
      >
        
      </Box>
    );
  }

  // If the user is signed in, show the normal layout.
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        width: '100%',
        backgroundColor: '#F3F5FF',
        boxSizing: 'border-box',
      }}
    >
      <NavBar user={user} onLogoutClick={handleLogout} />

      {/* Profile Dialog */}
      <Dialog
        open={showProfileDialog}
        fullWidth
        maxWidth="xs"
        onClose={() => {}}
        PaperProps={{
          sx: {
            borderRadius: 8,
            p: 2,
            boxShadow: 3,
            overflowY: 'visible',
          },
        }}
      >
        {/* Header Section */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            mb: 1.5,
          }}
        >
          <Box
            component="img"
            src={pawLogo}
            alt="Paw Logo"
            sx={{ width: 100, height: 100, mb: 1 }}
          />
          <Typography variant="h4" sx={{ fontWeight: 300, mb: 0.5, color: '#000626' }}>
            Welcome to PawPilot AI
          </Typography>
          <Typography variant="body2" sx={{ color: '#666' }}>
            Complete your profile below to get started!
          </Typography>
        </Box>

        {/* Form Fields Container */}
        <Box sx={{ px: 1 }}>
          {/* First Name */}
          <Box sx={{ width: '100%', mb: 0.8 }}>
            <Typography
              variant="subtitle2"
              sx={{ mb: 0, fontWeight: 500, textAlign: 'left', marginBottom: '-5px' }}
            >
              First Name *
            </Typography>
            <TextField
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="enter your first name here..."
              fullWidth
              margin="dense"
              size="small"
              required
              InputProps={{
                sx: {
                  borderRadius: '8px',
                  backgroundColor: '#FFFFFF',
                  '& input::placeholder': {
                    fontStyle: 'italic',
                    fontSize: '0.8em',
                  },
                },
              }}
            />
          </Box>

          {/* Last Name */}
          <Box sx={{ width: '100%', mb: 0.5 }}>
            <Typography
              variant="subtitle2"
              sx={{ mb: 0.25, fontWeight: 500, textAlign: 'left', marginBottom: '-5px' }}
            >
              Last Name *
            </Typography>
            <TextField
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="enter your last name here..."
              fullWidth
              margin="dense"
              size="small"
              required
              InputProps={{
                sx: {
                  borderRadius: '8px',
                  backgroundColor: '#FFFFFF',
                  '& input::placeholder': { fontStyle: 'italic', fontSize: '0.8em' },
                },
              }}
            />
          </Box>

          {/* Profession */}
          <Box sx={{ width: '100%', mb: 0.5 }}>
            <Typography
              variant="subtitle2"
              sx={{ mb: 0.25, fontWeight: 500, textAlign: 'left', marginBottom: '-5px' }}
            >
              Profession *
            </Typography>
            <TextField
              value={profession}
              onChange={(e) => setProfession(e.target.value)}
              placeholder="enter your profession here..."
              fullWidth
              margin="dense"
              size="small"
              required
              InputProps={{
                sx: {
                  borderRadius: '8px',
                  backgroundColor: '#FFFFFF',
                  '& input::placeholder': { fontStyle: 'italic', fontSize: '0.8em' },
                },
              }}
            />
          </Box>

          {/* Company Name */}
          <Box sx={{ width: '100%', mb: 0.5 }}>
            <Typography
              variant="subtitle2"
              sx={{ mb: 0.25, fontWeight: 400, textAlign: 'left', marginBottom: '-5px' }}
            >
              Company Name
            </Typography>
            <TextField
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              placeholder="enter your company name here..."
              fullWidth
              margin="dense"
              size="small"
              InputProps={{
                sx: {
                  borderRadius: '8px',
                  backgroundColor: '#FFFFFF',
                  '& input::placeholder': { fontStyle: 'italic', fontSize: '0.8em' },
                },
              }}
            />
          </Box>

          {/* Required Fields Note */}
          <Typography 
            variant="caption" 
            sx={{ 
              color: '#999', 
              textAlign: 'left', 
              width: '100%',
              fontSize: '0.65rem',
              fontStyle: 'italic',
              marginTop:'20px'
            }}
          >
            * Required fields
          </Typography>

          {/* Student Checkbox */}
          <Box sx={{ width: '100%', mb: 0.5 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isStudent}
                  onChange={(e) => setIsStudent(e.target.checked)}
                  size="small"
                  sx={{
                    '& .MuiSvgIcon-root': { fontSize: 16 },
                  }}
                />
              }
              label="Are you a student?"
              sx={{ m: 0, textAlign: 'left', width: '100%' }}
              componentsProps={{
                typography: {
                  sx: { fontSize: '0.8rem' },
                },
              }}
            />
          </Box>

          {/* School/Institution Name (if student) */}
          {isStudent && (
            <Box sx={{ width: '100%', mb: 0.5 }}>
              <Typography
                variant="subtitle2"
                sx={{
                  mb: 0.25,
                  fontWeight: 500,
                  textAlign: 'left',
                  marginBottom: '-5px'
                }}
              >
                School/Institution Name
              </Typography>
              <CollegeAutocomplete onCollegeSelect={(college) => {
                if (college) {
                  setSchoolName(college.name);
                }
              }} />
            </Box>
          )}

          {/* Save Profile Button */}
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              onClick={updateProfile}
              disabled={!firstName || !lastName || !profession}
              sx={{
                mt: 0,
                mb: 0,
                py: 1,
                px: 0.5,
                fontSize: '0.75rem',
                fontWeight: 300,
                borderRadius: '8px',
                width: '110px',
                backgroundColor: '#4C64D9',
                color: '#fff',
                boxShadow: 'none',
                border: '1px solid #ccc',
              }}
            >
              Save Profile
            </Button>
          </Box>
        </Box>
      </Dialog>

      {/* Main Content Area */}
      <Box sx={{ flex: 1, overflowY: 'auto' }}>
        {showResultsPage ? (
          <SearchResults
            query={query}
            output={output}
            isLoading={isLoading}
            handleGoBack={handleGoBack}
          />
        ) : (
          <Hero
            query={query}
            setQuery={setQuery}
            isLoading={isLoading}
            handleStartStream={handleStartStream}
            user={user}
          />
        )}
      </Box>

      <Footer />
    </Box>
  );
}

export default SearchStream;
